
import { Options, Vue } from "vue-class-component";
import PageWrapper from "@/layout/pageWrapper/index.vue";
import { languagesModule } from "@/store";

@Options({
  name: "Menu",
  components: { PageWrapper },
})
export default class Menu extends Vue {
  created(): void {
    languagesModule.Load();
  }
}
